import { Injectable } from '@angular/core';
import { BaseApiService } from '../apis';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

const RfiApiUrls = {
  rfiStepper: 'rfi/{rfiId}/stepper',
  rfiDocument: 'rfi/{rfiId}/rfi-document',
  home: 'rfi/{rfiId}/home',
};
@Injectable({
  providedIn: 'root',
})
export class RfiService {
  rfiIdBehaviorSubject = new BehaviorSubject<string>('');
  rfiOverview = new BehaviorSubject<any>({} as any);
  rfiDocument = new BehaviorSubject<any>({} as any);
  activeRfiSection = new BehaviorSubject<any>({} as any);
  activeRfiSectionIndex: number;
  navigateTo$ = new BehaviorSubject<string>('');
  rfiDrawer = new BehaviorSubject<any>({} as any);
  reload = new BehaviorSubject<any>({} as any);

  constructor(private baseApi: BaseApiService) {}

  getRfiStepper(rfiId: string): any {
    return this.baseApi.httpGet(RfiApiUrls.rfiStepper.replace('{rfiId}', rfiId)).pipe(tap(data => this.rfiOverview.next(data?.data)));
  }


  getCurrentRfiOverview() {
    return this.rfiOverview.asObservable();
  }

  getRfiDocument(rfiId: string) {
    return this.baseApi.httpGet(RfiApiUrls.rfiDocument.replace('{rfiId}', rfiId)).pipe(tap(response => this.rfiDocument.next(response?.data)));
  }

  createRfi(rfiId: string, data: {templateId: string, layoutId: string}) {
    return this.baseApi.httpPost(RfiApiUrls.rfiDocument.replace('{rfiId}', rfiId), data);
  }

  getStepCompletedStatus(step: string) {
    return this.rfiOverview.value.stepper.find(x => x.code === step).isCompleted;
  }

  getRfiId() {
    return this.rfiIdBehaviorSubject.asObservable();
  }

  getRfiOverview() {
    return this.rfiOverview.asObservable();
  }

  getHomeDetails(rfiId: string): any {
    return this.baseApi.httpGet(RfiApiUrls.home.replace('{rfiId}', rfiId));
  }

  updateRfiSection(index: number) {
    if(index > -1) {
      this.activeRfiSectionIndex = index;
      this.activeRfiSection.next(this.rfiDocument.value.sections[index]);
    }
  }

  getRfiActiveSection() {
    return this.activeRfiSection.asObservable();
  }

  goToPreviousRfiSection() {
    console.log(this.activeRfiSectionIndex);
    if(this.activeRfiSectionIndex > -1) {
      this.navigateTo$.next(this.rfiDocument.value.sections[this.activeRfiSectionIndex - 1].route)
    }
  }

  goToNextRfiSection() {
    if(this.activeRfiSectionIndex > 0 && this.rfiDocument.value.sections?.length > this.activeRfiSectionIndex +1) {
      this.navigateTo$.next(this.rfiDocument.value.sections[this.activeRfiSectionIndex + 1].route)
    }
  }

  updateHomeDetails(rfiId: string, data): any {
    return this.baseApi.httpPut(RfiApiUrls.home.replace('{rfiId}', rfiId), data);
  }

  updatePricingVisibility(rfiId: string, data): any {
    return this.baseApi.httpPost(RfiApiUrls.home.replace('{rfiId}', rfiId), data);
  }
}
